.roll{
    width: 100%;
    height: 226px;
    overflow: hidden;
    position: relative;
    left: 0px;
    top: 23px;

 
  }
  .anim {
    transition: all 0.5s;
    margin-top: -44px;
  }
  .ul{
    position: absolute;
    left: 0;
    top: 0px;
    right: -17px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 780px;
    height: 350px;
}
.li{
    width: 600px;
    font-size: 26px;
    line-height: 44px;
    margin-left: 10%;
  }